<template>
  <div class="log">
    <MyTable
      class="animated fadeInUp"
      :tableData="tableData"
      :configTable="configTable"
    ></MyTable>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getMemberLogApi } from "@/request/api.js";
import { mapState } from "vuex";
export default {
  components: {
    MyTable: () => import("@/components/MyTable.vue"),
  },
  data() {
    return {
      tableData: [],
      total: 0, 
      current_page: 1, 
      configTable: [
        {
          prop: "created_time",
          label: "时间",
          width: "240",
        },
        {
          prop: "channel_name",
          label: "操作",
          width: "",
        },
      ],
    };
  },
  created() {
    this.getMemberLogApi();
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  methods: {
    handleCurrentChange(val) {
      this.current_page = val;
      this.getMemberLogApi();
    },
    async getMemberLogApi() {
      const { code, results } = await getMemberLogApi({
        manor_id: this.farmID,
        page_num: this.current_page,
      });
      if (code != 0) return;
      this.current_page = results.page_num;
      this.tableData = results.data;
      this.total = results.count;
    },
  },
};
</script>

<style lang="less" scoped>
.log {
  width: calc(100% - 450px);
  min-height: 100vh;
  background-color: #071a2c;
  padding: 62px 48px 0;
}
</style>
